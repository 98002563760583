<template>
  <div class="a-detail">
    <div class="top-c">
      <div><span class="title">{{appraisalData ? appraisalData.title : ''}}</span><span class="note">{{appraisalData ? appraisalData.statusText : ''}}</span></div>
      <div class="remark">{{appraisalData ? appraisalData.remark : ''}}</div>
    </div>
    <div class="bottom-c" v-if="isCheck">
      <div class="b-c-t" v-if="khstatus === 'doing'">
        <div class="b-l" v-if="showCurrent">
          <div href="javascript:;" class="bkhr-item" :class="{'bkhr-item-web': clientType !== 'wap'}" @click="showDetail(item)" v-for="item in bkhList.filter(v => v.status === 'doing')" :key="item.id">
            <div>被考核人： {{item.userName}}</div>
            <div>代表单位： {{item.represent}}</div>
            <div class="yy-fz" v-if="item.df || item.df === 0">
              <strong>{{item.df}}</strong><span style="font-size: 22px;">分</span>
              <div class="sjx"></div>
            </div>
            <div v-for="(appraisalItem, index) in appraisalItems" :key="index">
              <ItemForm :ref="'appraisalItem_' + appraisalItem.id" :appraisal-item="appraisalItem" :his-value="(item.checkData.find(v => v.appraisalItemId === appraisalItem.id) || {value: null}).value"></ItemForm>
            </div>
            <div class="bkhr-btns">
              <fm-btn @click="clickdf(item)">完成</fm-btn>
            </div>
            <div class="show-all-btn">
              <div @click="showCurrent = false">查看全部被考核人></div>
            </div>
          </div>
        </div>
        <div class="b-r" v-else>
          <div class="br-t" @click="showCurrent = true">
            <div><i class="iconfont icon-jiantouyou"></i></div>
            返回会场
          </div>
          <div>
            <fm-table-new
              ref="table"
              :columns="columns"
              :data-list="bkhList"
              :export-file-name="exportFileName"
              border="row"
              :stripe="false">
              <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
            </fm-table-new>
          </div>
        </div>
      </div>
      <div class="not-text" v-else>
        {{khstatus === 'waitDoing' ? '欢迎来到' + (appraisalData ? appraisalData.title : '') + '，考核即将开始，请您稍等。' : '本次活动已结束，感谢您的参与。'}}
      </div>
    </div>
    <fm-modal
      :title="'给' + (chooseDataDetail ? chooseDataDetail.userName : '') + '打分'"
      v-model="openDialogDf"
      :mask-closable="false"
      :width="clientType !== 'wap' ? '600' : '80%'"
      @handleClose="openDialogDf = false">
      <div v-for="(item, index) in appraisalItems" :key="index">
        <ItemForm :ref="'table_appraisalItem_' + item.id" :appraisal-item="item"></ItemForm>
      </div>
      <div class="bkhr-btns" style="display: flex;justify-content: center;margin-top: 20px;">
        <fm-btn @click="clickdfTable(chooseDataDetail)">完成</fm-btn>
        <fm-btn @click="openDialogDf = false">取消</fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import {
  appraisalRequest as request,
  appraisalGroupDetailRequest,
  appraisalResultRequest,
  appraisalItemRequest
} from '../../api'

import TableActions from '@/components/base/TableActions'

import ItemForm from './cmp/ItemForm'

export default {
  components: {
    TableActions,
    ItemForm
  },
  data () {
    return {
      dfing: false,
      needLoad: false,
      showCurrent: true,
      dfvalue: [],
      openDialogDf: false,
      isCheck: false,
      appraisalId: null,
      appraisalItems: [],
      dfRemark: '',
      mxList: [],
      bkhList: [],
      appraisalGroupDetailId: null,
      appraisalData: null,
      chooseDataDetail: null,
      tableActions: [{
        key: 'edit',
        label: '打分',
        show: (data) => {
          return ['doing', 'over'].includes(data.status)
        }
      }]
    }
  },
  computed: {
    clientType () {
      return this.$store.getters.clientType
    },
    khstatus () {
      let status = 'doing'
      if (this.bkhList.filter(v => v.status === 'doing').length === 0 && this.bkhList.filter(v => v.status === 'over').length === 0) {
        status = 'waitDoing'
      }
      if (this.bkhList.filter(v => v.status === 'doing').length === 0 && this.bkhList.filter(v => v.status === 'ready').length === 0) {
        status = 'over'
      }
      return status
    },
    columns () {
      let data = [{
        field: 'userName',
        title: '执行人'
      },
      {
        field: 'represent',
        title: '代表单位'
      },
      {
        field: 'df',
        title: '得分'
      }]
      let a = [{
        title: '操作',
        slot: 'actions',
        width: 50,
        fixed: true,
        export: false,
        configurable: false
      }]
      return data.concat(a)
    },
    formParmsDf: {
      get () {
        let data = []
        // let data = this.appraisalRule.map((v, index) => {
        //   return {
        //     type: 'input',
        //     label: v.title || '分值',
        //     key: 'value' + index,
        //     placeholder: v.min + '-' + v.max,
        //     check: {
        //       rules: {
        //         type: 'number'
        //       }
        //     }
        //   }
        // })
        data.push({
          type: 'textarea',
          label: '评价',
          key: 'remark'
        })
        return data
      }
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    },
    appraisalGroupDetailStatusList () {
      return this.$store.getters.appraisalGroupDetailStatusList
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.id) {
      next(vm => vm.$nextTick(vm.init))
    } else {
      next(from)
    }
  },
  async created () {
    this.needLoad = true
  },
  beforeDestroy () {
    this.needLoad = false
  },
  methods: {
    exportFileName () {
      return '被考核人' + this.$datetime.format(new Date(), 'YMD')
    },
    async init () {
      this.needLoad = true
      this.appraisalId = this.$router.history.current.query.id
      this.loadData()
      await this.loadBaseData()
      if (this.isCheck && this.appraisalItems.length > 0) {
        this.loadDetailList()
        this.timeOutLoad()
      } else if (!this.isCheck) {
        this.$notice.info({
          title: '系统提示',
          desc: '无考核权限'
        })
      } else {
        this.$notice.info({
          title: '系统提示',
          desc: '无考核项目'
        })
      }
    },
    onBlur (i, value) {
      let ruleItem = this.appraisalRule[i]
      let isOk = true
      if (isNaN(value) || Number(value) < Number(ruleItem.min) || Number(value) > Number(ruleItem.max)) {
        isOk = false
      }
      let index = ruleItem.pre === '整数' ? 0 : (ruleItem.pre === '两位小数' ? 2 : 1)
      if (isOk && String(value).includes('.')) {
        isOk = String(value).split('.')[1].length < (index + 1)
      }
      if (!isOk) {
        this.$notice.warning({
          title: '系统提示',
          desc: '请输入合理分值'
        })
      }
      return isOk
    },
    timeOutLoad () {
      if (this.needLoad) {
        setTimeout(() => {
          this.loadDetailList()
          this.timeOutLoad()
        }, 2000)
      }
    },
    async clickdf (beAppraisalGroupDetail) {
      let parmList = []
      let i = 0
      while (i < this.appraisalItems.length) {
        let item = this.appraisalItems[i]
        let p = null
        try {
          p = this.$refs['appraisalItem_' + item.id][0].getValue()
          Object.assign(p, {
            appraisalId: this.appraisalId,
            appraisalItemId: item.id,
            beAppraisalGroupDetailId: beAppraisalGroupDetail.id,
            appraisalGroupDetailId: this.appraisalGroupDetailId
          })
          parmList.push(p)
        } catch (e) {
          console.log(e)
          return
        }
        i += 1
      }
      if (this.dfing) {
        return
      }
      this.dfing = true
      i = 0
      while (i < parmList.length) {
        await appraisalResultRequest.add(parmList[i])
        this.$refs['appraisalItem_' + parmList[i].appraisalItemId][0].setValue({
          remark: null,
          value: null
        })
        i += 1
      }
      this.$notice.success({
        title: '系统提示',
        desc: '打分完成'
      })
      await this.loadDetailList()
      this.dfing = false
    },
    async clickdfTable (beAppraisalGroupDetail) {
      let parmList = []
      let i = 0
      while (i < this.appraisalItems.length) {
        let item = this.appraisalItems[i]
        let p = null
        try {
          p = this.$refs['table_appraisalItem_' + item.id][0].getValue()
          Object.assign(p, {
            appraisalId: this.appraisalId,
            appraisalItemId: item.id,
            beAppraisalGroupDetailId: beAppraisalGroupDetail.id,
            appraisalGroupDetailId: this.appraisalGroupDetailId
          })
          parmList.push(p)
        } catch (e) {
          console.log(e)
          return
        }
        i += 1
      }
      if (this.dfing) {
        return
      }
      this.dfing = true
      i = 0
      while (i < parmList.length) {
        await appraisalResultRequest.add(parmList[i])
        this.$refs['table_appraisalItem_' + parmList[i].appraisalItemId][0].setValue({
          remark: null,
          value: null
        })
        i += 1
      }
      this.$notice.success({
        title: '系统提示',
        desc: '打分完成'
      })
      await this.loadDetailList()
      this.dfing = false
      this.openDialogDf = false
    },
    loadMxList () {
      let parm = {
        beAppraisalGroupDetailId: this.chooseDataDetail.id
      }
      appraisalResultRequest.getDetail(parm).then((data) => {
        this.mxList = data
      })
    },
    async loadBaseData () {
      let parm = {
        appraisalId: this.appraisalId
      }
      let data = await appraisalGroupDetailRequest.getMy(parm)
      data.forEach((v) => {
        if (v.appraisalGroupType === 'check') {
          this.appraisalGroupDetailId = v.id
          this.isCheck = true
        }
      })
      if (this.isCheck) {
        let appraisalItems = await appraisalItemRequest.get(parm)
        appraisalItems.forEach(v => v.ruleMap = v.rule ? JSON.parse(v.rule) : {
          type: 'input'
        })
        this.appraisalItems = appraisalItems
      }
    },
    showDetail (data) {
      this.chooseDataDetail = data
    },
    tableAction (parm) {
      this.chooseDataDetail = parm.data
      this.openDialogDf = true
    },
    formSubmitDf (data, resolve) {
      let i = 0
      let ok = true
      let value = 0
      while (ok && i < this.appraisalRule.length) {
        if (!this.onBlur(i, data['value' + i])) {
          ok = false
        }
        value += Number(data['value' + i])
        i += 1
      }
      if (!ok) {
        resolve()
        return
      }
      data.value = value
      let parm = Object.assign({
        appraisalId: this.appraisalId,
        beAppraisalGroupDetailId: this.chooseDataDetail.id,
        appraisalGroupDetailId: this.appraisalGroupDetailId,
        appraisalItemId: this.appraisalItem.id
      }, data)
      appraisalResultRequest.add(parm).then(() => {
        this.$notice.success({
          title: '系统提示',
          desc: '打分完成'
        })
        this.openDialogDf = false
        resolve()
        this.loadDetailList()
      })
    },
    loadData () {
      this.loading = true
      let parm = {id: this.appraisalId}
      request.get(parm).then((data) => {
        data.forEach(v => v.statusText = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label)
        if (data.length == 0) {
          this.$notice.info({
            title: '系统提示',
            desc: '考核不存在'
          })
        } else {
          this.appraisalData = data[0]
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    async loadDetailList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'becheck'
      }
      let mxList = await appraisalResultRequest.getDetail({appraisalGroupDetailId: this.appraisalGroupDetailId})
      let mxMap = {}
      mxList.forEach((v) => mxMap[v.beAppraisalGroupDetailId] = mxMap[v.beAppraisalGroupDetailId] ? mxMap[v.beAppraisalGroupDetailId].concat(v) : [v])
      let datas = await appraisalGroupDetailRequest.get(parm)
      datas.sort((a, b) => (a.orderNum || 1000) - (b.orderNum || 1000))
      datas.forEach((v) => {
        v.checkData = mxMap[v.id] || []
        v.df = null
        v.checkData.forEach(v1 => {
          v.df += v1.value
        })
        v.statusText = (this.appraisalGroupDetailStatusList.find(v1 => v1.key === v.status) || {label: ''}).label
      })
      this.bkhList = datas
    }
  }
}
</script>

<style lang="less" scoped>
.a-detail {
  .fm-input-new {
    border: 1px solid #585858;
  }
  .fz-input {
    .fm-input-new {
      width: 142px !important;
      height: 32px;
      border-radius: 16px;
      border: 1px solid #585858;
    }
  }
  .bkhr-btns {
    .fm-btn-norm {
      background: #FB6D8F;
      color: #fff;
      height: 32px !important;
      width: 96px !important;
      border-radius: 16px !important;
    }
  }
}
</style>


<style lang="less" scoped>
.br-t {
  font-size: 14px;
  color: rgba(0,0,0,0.65);
  line-height: 36px;
  display: flex;
  div {
    margin: 9px 9px 0 2px;
    transform: rotate(180deg);
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: rgba(0,0,0,0.65) !important;
    }
  }
}
.show-all-btn {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  div {
    line-height: 22px;
    color: #F4628F;
    font-size: 14px;
  }
}
.top-c {
  background: #fff;
  padding: 14px 28px;
  .title {
    line-height: 22px;
    font-size: 16px;
    color: #333145;
  }
  .note {
    color: #00C8C4;
  }
  .remark {
    color: rgba(0,0,0,0.45);
    font-size: 14px;
    line-height: 23px;
    padding-top: 14px;
  }
}
.not-text {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
  padding: 0 2rem 4rem;
}
.bkhr-list-none {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
}
.bkhr-list-none {
  flex: 1;
}
.bkhr-item-web {
  max-width: 481px;
}
.bkhr-item {
  position: relative;
  padding: 16px 20px;
  margin: 8px;
  line-height: 2rem;
  background: #FDFDFD;
  color: rgba(0,0,0,0.65);
  line-height: 36px;
  font-size: 14px;
  .yy-fz {
    font-size: 40px;
    line-height: 45px;
    position: absolute;
    top: 0;
    padding-top: 16px;
    text-align: center;
    right: 36px;
    width: 82px;
    height: 82px;
    background: #FFCED1;
    color: #fff;
    .sjx {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 0;
      border-left: 41px solid transparent;
      border-right: 41px solid transparent;
      border-bottom: 25px solid #fff;
    }
  }
  .bkhr-btns {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
.b-c-t {
  display: flex;
  flex: 1;
}
.b-r {
  flex: 1;
  margin: 8px;
  background: #fff;
}
.b-l {
  flex: 1;
}
.a-detail {
  display: flex;
  flex-direction: column;
  background: #fff7f9;
}
.content {
  flex: 1;
  display: flex;
  overflow-x: auto;
}
.group-item {
  min-width: 30%;
  max-width: 50%;
  flex: 1;
  margin: 1rem;
  border: 1px solid #eee;
}
.bottom-c {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.n-g {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #999;
}
.a-remark {
  color: #777;
  margin: 1rem 2rem;
  line-height: 2;
}
</style>